async function loadMainNav() {
    const response = await fetch(`https://directus.stonevibes.de/items/startseite/?fields[]=
        seite.titel,seite.id,seite.sort,seite.boulderdatenbank,seite.routendatenbank,
        seite.subpages.titel,seite.subpages.id,seite.subpages.sort,seite.subpages.boulderdatenbank,seite.subpages.routendatenbank,
        seite.subpages.subpages.titel,seite.subpages.subpages.id,seite.subpages.subpages.sort,seite.subpages.subpages.boulderdatenbank,seite.subpages.subpages.routendatenbank,
        seite.subpages.subpages.subpages.titel, seite.subpages.subpages.subpages.id,seite.subpages.subpages.subpages.sort,seite.subpages.subpages.subpages.boulderdatenbank,seite.subpages.subpages.subpages.routendatenbank`);
    const data = await response.json();
    const navi = data.data.seite.sort(compare);
    return navi;
}

let storeSubNav = [];


async function loadSubNav(id) {

    const result = storeSubNav.find( nav => nav.id === id );

    if (result != undefined) {
        return result.subNavi;
    }

    const response = await fetch(`https://directus.stonevibes.de/items/seiten/${id}?fields[]=
        titel,id,sort,boulderdatenbank,routendatenbank,
        subpages.titel,subpages.id,subpages.sort,subpages.boulderdatenbank,subpages.routendatenbank,
        subpages.subpages.titel,subpages.subpages.id,subpages.subpages.sort,subpages.subpages.boulderdatenbank,subpages.subpages.routendatenbank,
        subpages.subpages.subpages.titel,subpages.subpages.subpages.id,subpages.subpages.subpages.sort,subpages.subpages.subpages.boulderdatenbank,subpages.subpages.subpages.routendatenbank`);
    const data = await response.json();
    let subNavi = {}

    if (data.data.subpages != null) {
        subNavi = data.data.subpages.sort(compare);
    }

    storeSubNav.push({id, subNavi});
    return subNavi;
}

async function loadPageData(id) {
    const response = await fetch('https://directus.stonevibes.de/items/seiten/' + id + '?fields[]=id,titel,subtitel,text,titelbild,iframe_link,anhang.directus_files_id.id,anhang.directus_files_id.title,anhang.directus_files_id.type,anhang.directus_files_id.filesize')
    const data = await response.json();
    return data.data;
}

let storeTubNav = [];

async function loadTabNav(id) {

    const result = storeTubNav.find( nav => nav.id === id );

    if (result != undefined) {
        return result.tabNavi;
    }

    //console.log("loadTabNav");

    let tabNavi = {}
    let ltwResponse;
    let ltwData;

    if (id == 0) {
        ltwResponse = await fetch(`https://directus.stonevibes.de/items/startseite/?fields[]=seite.titel,seite.id,seite.sort,seite.boulderdatenbank,seite.routendatenbank`);
        ltwData = await ltwResponse.json();
        tabNavi = ltwData.data.seite.sort(compare);
    }else{
        ltwResponse= await fetch(`https://directus.stonevibes.de/items/seiten/${id}?fields[]=parent_id,subpages.titel,subpages.id,subpages.sort,subpages.boulderdatenbank,subpages.routendatenbank`);
        ltwData = await ltwResponse.json();

        if (ltwData.data.subpages != null) {
        //    tabNavi = ltwData.data.subpages.sort(compare);
        }else{
            ltwResponse = await fetch(`https://directus.stonevibes.de/items/seiten/${ltwData.data.parent_id}?fields[]=parent_id,subpages.titel,subpages.id,subpages.sort,subpages.boulderdatenbank,subpages.routendatenbank`);
            ltwData = await ltwResponse.json();
        }
        tabNavi = ltwData.data.subpages.sort(compare);
    }

    storeTubNav.push({id, tabNavi});

    return tabNavi;
}


async function loadStartPageData() {
    const response = await fetch('https://directus.stonevibes.de/items/startseite/?fields[]=*.*')
    const data = await response.json();
    return data.data;
}





async function loadGebiete(typ) {
    const response = await fetch('https://directus.stonevibes.de/items/'+typ+'_gebiete/?fields[]=*&limit=-1&sort=id')
    const data = await response.json();

    const routenTree = makeTree(data.data)
    return {tree:routenTree, data:data.data}
}

async function loadItems(id,typ) {
    const response = await fetch('https://directus.stonevibes.de/items/'+typ+'_items/?fields[]=*,grad.grade&filter['+typ+'_gebiet][_eq]='+id+'&sort=grad')
    const data = await response.json();
    return data.data;
}


    function makeTree(data){
        //console.log(data);
        let items = [];

        let lostItems = [];
        let lostItems2 = [];

        for (let g of data) {
            if (g.parent_id != null && g.parent_id != 1) {

                let out = findById(items, g.parent_id);

                if (out != undefined) {
                    if (out.hasOwnProperty("children")) {
                        out.children.push(makeTreeItem(g));
                    }else{
                        out.children = [makeTreeItem(g)];
                    }
                }else{
                    lostItems.push(g);
                }
            }else{
                if (g.parent_id != null){
                    items.push(makeTreeItem(g));
                }
            }
        }


        for (let c of lostItems) {
            let out = findById(items, c.parent_id);
            if (out != undefined) {

                if (out.hasOwnProperty("children")) {
                    out.children.push(makeTreeItem(c));
                }else{
                    out.children = [makeTreeItem(c)];
                }
           }else{
                lostItems2.push(c);
            }
        }
        return items;
    }



    function makeTreeItem(data){

        let item = {id:data.id, name:data.title}
        // if (data.bdb_items != null) {
        //
        //     item.name = data.title+` (${data.bdb_items.length})`
        //     item.to = `/boulder/${data.title}/${data.id}`;
        // }
        return item;
    }



    function findById(o, id) {
        //Early return
        if( o.id === id ){
          return o;
        }
        var result, p;
        for (p in o) {
            if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
                result = findById(o[p], id);
                if(result){
                    return result;
                }
            }
        }
        return result;
    }




function makeBreadcrumb(breadcrumb, pfad, id) {
    for (let p of breadcrumb) {
        pfad[p.id] = [{text: p.titel, disabled: false, to:"/"+p.titel + "/" + p.id, id:p.id, link:true, exact:true}];
        pfad[p.id].unshift(...pfad[id])
        if (p.subpages != null) {
            makeBreadcrumb(p.subpages, pfad, p.id)
        }
    }



    return pfad;
}

function getBreadcrumb(navi){
    let item = [[{
            text: 'StoneVibes',
            disabled: false,
            to: '/',
    }]];
    let breadcrumb = makeBreadcrumb(navi, item, 0);

    breadcrumb[104]=[{
            text: 'StoneVibes',
            disabled: false,
            to: '/'
    },{
            text: 'Impressum',
            disabled: true,
            exact: true,
            link:true,
            id:104,
            to: '/Impressum/104'
    }];

    breadcrumb[105]=[{
            text: 'StoneVibes',
            disabled: false,
            to: '/'
    },{
            text: 'Datenschutzerklärung',
            disabled: true,
            exact: true,
            link:true,
            id:104,
            to: '/Datenschutzerklärung/105'
    }];

    return breadcrumb;
}



function compare(a, b) {
    if (a.subpages) {
        a.subpages.sort(compare);
    }
    if (a.sort < b.sort) {
        return -1;
    }
    if (a.sort > b.sort) {
        return 1;
    }
    return 0;
}


function makePath(data){
            if (data.boulderdatenbank){
                return '/boulder/start/0';
            }else if (data.routendatenbank) {
                return '/routen/start/0';
            }else{
                return '/'+data.titel+'/'+data.id;
            }
        }

export {
    loadMainNav,
    getBreadcrumb,
    loadSubNav,
    loadPageData,
    loadTabNav,
    loadStartPageData,
    loadGebiete,
    loadItems,
    makePath
}
