import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
            primary: '#000',
            secondary: '#fff',
            accent: '#000',
            error: '#FF1744'
      },
    },
  },
})
