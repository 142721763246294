<template>
<v-container>
    <v-breadcrumbs divider=">" :items="bc" class="px-0 mb-5 mb-sm-0" ></v-breadcrumbs>
    <div class="single-post-details-area">
        <router-view :bc="bc"></router-view>
    </div>
</v-container>
</template>

<script>
export default {
    name: 'Content',
    props: ['bc']
}
</script>
