export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import( '../views/Home.vue'),
        meta: { type:"seite"},
    },
    {
        path: '/boulder',
        name: "Boulder",
        meta: { type:"boulder"},
        component: () => import( '../views/Boulder.vue'),
            children: [
            {
              path: '/boulder/*/:id',
              component: () => import( '../views/Boulder.vue'),
              meta:  { type:"boulder"}
            }
        ]
    },
    {
        path: '/routen',
        name: "Routen",
        meta:  { type:"routen"},
        component: () => import( '../views/Boulder.vue'),
            children: [
            {
              path: '/routen/*/:id',
              component: () => import( '../views/Boulder.vue'),
              meta:  { type:"routen"}
            }
        ]
    },
    {
        path: '/*/:id(\\d+)',
        name: "link",
        component:  () => import( '../views/Seite.vue'),
        meta: { type:"seite" }
    },
    {
        path: '/*',
        name: "link",
        component:  () => import( '../views/Home.vue'),
        meta: { type:"seite" }
    }
]
