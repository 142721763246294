<template>
<v-app>
<!-- :fade-img-on-scroll="$vuetify.breakpoint.smAndDown" -->
    <v-app-bar app color="#9c8d63" :prominent="$vuetify.breakpoint.mdAndUp" :shrink-on-scroll="false"  elevate-on-scroll  dark src="/assets/header/a.jpg">

        <template v-slot:img="{ props }">
            <v-img class="hidden-md-and-up" v-bind="props" gradient="180deg, rgba(255,255,255,.9) 30%, rgba(156,141,99,.7) "></v-img>
            <v-img class="hidden-sm-and-down" v-bind="props" gradient="120deg, rgba(255,255,255,.9) 10%, rgba(156,141,99,.7) 50%"></v-img>
        </template>

        <router-link to="/" v-if="$vuetify.breakpoint.mdAndUp" name="Startseite"><v-img class="ma-2" src="/assets/logo.png" max-height="80" max-width="210" contain></v-img></router-link>
        <router-link to="/" v-if="$vuetify.breakpoint.smAndDown" name="Startseite"><v-img src="/assets/logo.png" max-height="48" max-width="210" contain></v-img></router-link>

        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" light class="hidden-md-and-up"></v-app-bar-nav-icon>
        <AppBarNav :navi="mainNav" v-if="$vuetify.breakpoint.mdAndUp" />

        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown" >
            <TabNav v-if="$vuetify.breakpoint.smAndDown"/>
        </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" v-if="$vuetify.breakpoint.smAndDown">
        <NavigationDrawer :navi="mainNav" v-if="$vuetify.breakpoint.smAndDown"/>
    </v-navigation-drawer>

    <v-main>
        <Content v-if="load" :bc="breadcrumb" />
    </v-main>

    <v-footer padless dark>
        <v-card-text class="py-2 white--text text-center">
            © {{ new Date().getFullYear() }} — <strong>StoneVibes</strong>
            <router-link to="/Impressum/104" class="white--text d-inline-block px-2 " name="Impressum">Impressum</router-link>
            <router-link to="/Datenschutzerklärung/105" class="white--text" name="Datenschutzerklärung">Datenschutzerklärung</router-link>
        </v-card-text>
    </v-footer>
</v-app>
</template>

<script>
import Content from './components/Content';
import {loadMainNav, getBreadcrumb} from './api/api'


export default {
    name: 'StoneVibes',
    data: () => ({
        drawer: false,
        mainNav: [],
        breadcrumb: [],
        crumbs:[],
        load:false
    }),
    components: {
        Content,
        AppBarNav: () => import('./components/navigations/AppBarNav'),
        NavigationDrawer: () => import('./components/navigations/NavigationDrawer'),
        TabNav: () => import('./components/navigations/TabNav')
    },
    computed:{

    },
    mounted() {

    },
    async created() {
        this.mainNav = await loadMainNav();
        this.crumbs = getBreadcrumb(this.mainNav);


        if (this.$route.meta.type == "seite") {
            this.breadcrumb = this.crumbs[this.$route.params.id || 0];
        }else if (this.$route.meta.type == "boulder") {
            this.breadcrumb = [...this.crumbs[0],{disabled: false,id: 9,text: "Bouldern",to: "/Bouldern/9"},{disabled: true,text: "Boulderdatenbank",to: "/boulder"}];
        }else{
            this.breadcrumb = [...this.crumbs[0],{disabled: false,id: 9,text: "Klettern",to: "/Klettern/35"},{disabled: true,text:"Routendatenbank",to: "/routen"}];
        }
        this.load = true;

    },
    watch: {
        '$route': function() {
            if (this.$route.meta.type == "seite") {
                this.breadcrumb = this.crumbs[this.$route.params.id || 0];
            }else if (this.$route.meta.type == "boulder") {
                this.breadcrumb = [...this.crumbs[0],{disabled: false,id: 9,text: "Bouldern",to: "/Bouldern/9"},{disabled: true,text: "Boulderdatenbank",to: "/boulder"}];
            }else{
                this.breadcrumb = [...this.crumbs[0],{disabled: false,id: 9,text: "Klettern",to: "/Klettern/35"},{disabled: true,text: "Routendatenbank",to: "/routen"}];
            }
            //this.load = true;
        }
    },
};
</script>
